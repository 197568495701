.main-container{
    background: rgb(0, 21, 42);
    background: linear-gradient(0deg, rgb(187, 201, 216) 0%, rgb(255, 255, 255) 100%);
   margin: 0;
   padding: 0;
   min-height: 100vh;
   width: 100%;
 display: flex;
 justify-content: center;
 /* align-items: center; */
 flex-direction: row;
}
.main-content{
    width: 80%;
}