.space-page {
    background: url('../../../assets/gen/space.jpeg') no-repeat center center fixed;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
 .error-message{
  color: red;
  margin-top: 10px;
 }
  .contact-form-container {
    background-color: #00152aea;
    backdrop-filter: blur(5px);
    padding: 2rem;
    color: #fff;
    max-width: 550px;
    margin: 0 auto;
    border-radius: 8px;
  }
  
  .contact-form-title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
  
  .form-label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  textarea:focus, input:focus{
    outline: none;
}
  
  .contact-form-input,
  .contact-form-textarea {
    padding: 0.5rem;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
  }
  
  .contact-form-textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .contact-form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 45px;
    border: none;
    background-color: #0f62aa;
    font-size: large;
    border-radius: 0px !important;
    margin-top: 30px;
    color: #fff;
    cursor: pointer;
  }
  
  .contact-form-button:hover {
    background-color: #3691e1;
  }
  @media screen and (max-width: 600px) {
    .space-page {
      background: url('../../../assets/gen/space.jpeg') no-repeat center center fixed;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }
  }