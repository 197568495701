.footer{
display: flex;
flex-direction: row;
justify-content: center;
text-align: center;
align-items: center;
padding: 5px;
font-size: smaller;
}
.copyrightText{
    margin-bottom: 0px;
}