.space-bg {
  background: url('../../../assets/gen/space.jpeg') center center fixed;
  background-color: rgba(0, 0, 0, 0.641);
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  color: #fff;
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  flex: 1;
  padding-bottom: 3%;
}
.icon-style{
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.3vh;
  height: 4.3vh;
  border-radius: 50%;
  margin-right: 1vh;
}
.about-us-container {
  background: rgb(0, 21, 42);
  background: linear-gradient(0deg, rgb(187, 201, 216) 0%, rgb(255, 255, 255) 100%);
  padding: 0;
  margin: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  flex: 1;
}
.container{
  margin-inline: 10%;
}
.btn{
  background-color: #00152a;
  color: #fff;
  border: none;
  height: 45px;
  padding-inline: 30px;
  cursor: pointer;
}
.services-container{
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
}
.services-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.services-listItem{
  background-color: #162636a9;
  backdrop-filter: blur(10px);
  color: #fff;
  min-height: 50px;
  min-width: 80%;
  display: flex;
 align-items: center;
padding-inline: 10px;
 border-radius: 8px;
 cursor: pointer;
 padding-left: 20px;
 margin-block: 10px;
}
.get-started-card{
  margin-top: 20px;
  background-color: #dedede;
  height: 100%;
  padding: 30px;
  border-radius: 8px;
  color: #000;
}
.list-items{
  max-height: 500px;
  width: 45%;
  overflow: auto;
}
.active{
  background-color: rgb(245, 245, 245);
  color: #000;
}
.listItem-Content-Card{
  max-height: 500px;
 overflow: auto;
  background-color: #00152aa1;
  backdrop-filter: blur(10px);
  width: 50%;
 border-radius: 8px;
 padding-inline: 30px;
}

.about-us-content {
  display: flex;
   flex-direction: row;
   justify-content: space-between;
   height: 100%;
   width: 100%;
   padding-inline: 140px;
}
.intro-text{
  width: 50%;
}
.gif-animation{
  width: 50%;
}

.about-icon {
  background-color: #031d36f9;
  width: 40px;
  height: 40px;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 100%;
}

.about-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  padding-inline: 20px;
}

.about-content {
  background-color: #171d2386;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 0 0 30%;
  padding: 20px;
  min-height: 360px;
  margin: 5px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.mobile-view{
  display: none;
}

@media screen and (max-width: 600px) {
  .mobile-view{
    display: block;
  }
  .desktop-view{
    display: none;
  }
  .services-content{
    display: flex;
    flex-direction: column;
  }
  .list-items{
    max-height: 500px;
    width: 100%;
    overflow: auto;
  }
  
  .about-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
  }
  .about-us-header{
    text-align: left;
    font-size: 30px !important;
  }
  .gif-animation{
    width: 100%;
  }
  .BotGIF{
    height: 100%;
    width: 100%;
  }
  .space-bg {
    background: url('../../../assets/gen/space.jpeg');
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.641);
    background-blend-mode: darken;
    background-size: cover;
    background-position: center;
    margin: 0;
    padding: 0;
    color: #fff;
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  padding-bottom: 5%;
  }

  .intro-text{
    width: 80%;
  }

  .about-us-container {
    background: rgb(0, 21, 42);
    background: linear-gradient(0deg, rgb(187, 201, 216) 0%, rgb(255, 255, 255) 100%);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
  }
  
  .about-us-content {
    display: flex;
     flex-direction: column;
     height: 100%;
     width: 100%;
     align-items: center;
     padding-inline: 10px;
     margin-bottom: 40px;
  }
  .container{
    margin-inline: 5%;
  }
}