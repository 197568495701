@import 'themeStyle.css';
.space-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .space-title {
    font-size: 2.5rem;
  }
  
  .space-nav ul {
    display: flex;
    list-style: none;
  }
  
  .space-nav li {
    margin-right: 1rem;
  }
  
  .space-nav a {
    background-color: var(--bg-color);
    color: var(--text-color);
    text-decoration: none;
  }
  
  /* Section */
  .space-section {
    margin-bottom: 2rem;
  }
  
  .space-section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  /* Footer */
  .space-footer {
    text-align: center;
    margin-top: 2rem;
    background-color: var(--bg-color);
    color: var(--text-color);
  }

  .home-root {
    margin: 0;
    color: var(--text-color);
    height: 100vh;
    background-image: url('/src/assets/gen/space.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }

 .home-container{
  padding: 0px;
  margin: 0px;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10%;
 }
 .text-section{
  height: 100%;
  text-align: left;
  align-items: left;
  display: flex;
  width: 50%;
  font-size: 28px;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
 }
 .image-section{
  height: 100%;
  align-items: center;
  display: flex;
  width: 50%;
  padding: 5%;
 }
 .pre-audit-logo{
  max-height: 140px;
 }
 .home-button, .home-button:hover{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  height: 65px;
  border: none;
  background-color: #094b8d;
  font-size: large;
  margin-top: 30px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
 }
 
@media screen and (max-width: 992px) {
  .home-root {
    margin: 0;
    color: var(--text-color);
    min-height: 100vh;
    background-image: url('/src/assets/gen/space.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }

  .home-container{
    padding: 0px;
    margin: 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 2%;
   }
   .home-button, .home-button:hover{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    border: none;
    background-color: #094b8d;
    font-size: large;
    border-radius: 0px !important;
    margin-top: 30px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
   }
   .text-section{
    height: 100%;
    text-align: left;
    align-items: left;
    justify-content: left;
    display: flex;
    width: 100%;
    font-size: 25px;
    color: var(--text-color);
    margin-bottom: 0px;
    padding: 5%;
   }
   .image-section{
    height: 100%;
    align-items: flex-start;
    display: flex;
    width: 100%;
   }
   .pre-audit-logo{
    max-height: 125px;
   }
}

@media screen and (max-width: 600px) {
  .home-root {
    margin: 0;
    color: var(--text-color);
    height: 100%;
    background-image: url('/src/assets/gen/space.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }
  .home-container{
    padding: 0px;
    margin: 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 2%;
   }
   .home-button, .home-button:hover{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    border: none;
    background-color: #094b8d;
    font-size: large;
    border-radius: 6px;
    margin-top: 30px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
   }
   .text-section{
    height: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    width: 100%;
    font-size: 25px;
    color: var(--text-color);
    margin-bottom: 0px;
   }
   .image-section{
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
   }
   .pre-audit-logo{
    max-height: 70px;
    margin-bottom: 100px;
   }
}